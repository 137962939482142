a {
  color: #14a3c4;
}

.bg-dark {
  background-color: #14a3c4 !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgb(196, 255, 255);
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: rgb(228, 255, 255);
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#pdf-canvas {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

#pdf-canvas > canvas {
  max-width: 90%;
  height: auto !important;
}

#page-loader {
  height: 100px;
  line-height: 100px;
  text-align: center;
  display: none;
  color: #999999;
  font-size: 13px;
}

#download-image {
  width: 150px;
  display: block;
  margin: 20px auto 0 auto;
  font-size: 13px;
  text-align: center;
}

#left-container {
  max-height: 700px;
  min-height: 600px;
  overflow: scroll;
  background-color: gray;
}

#c {
  border: 3px solid black;
}
#loadjsonabbrevelem {
  display: none;
}

/* For the login screen. */

#loginjumbo {
  margin-top: 50px;
  box-shadow: 3px 2px 3px #cccccc;
  padding: 0px;
  border-radius: 15px;
}
#loginjumboleft {
  background-color: #14a3c4;
  color: white;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

h1 {
  font-size: 35px;
}
h1 a {
  color: black;
}
h1 a:hover {
  color: black;
  background-color: lightgrey;
  text-decoration: none;
}

#loginjumboleft h1 {
  font-size: 60px;
  padding: 50px;
}
#loginjumboleftpara {
  padding-top: 80px;
}

#loginjumboright {
  background-color: white;
  color: black;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 50px;
}

#loginjumboright p {
  text-align: left;
}

footer p {
  margin-top: 50px;
}

#btnSignIn:hover {
  background-color: #0069d9;
}
#btnSignIn {
  background-color: #14a3c4;
}

#zoomInBtn {
  z-index: 666;
  width: 40px;
  border: 3px solid black;
  margin-bottom: 10px;
  font-weight: bold;
}
#zoomOutBtn {
  z-index: 666;
  width: 40px;
  border: 3px solid black;
  font-weight: bold;
}
#zoomBtns {
  position: absolute;
  right: 70px;
  bottom: 60px;
}

@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}

#ResumeContainer {
  margin: auto;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFDocument {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PDFPage {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.PDFPageOne {
  margin-bottom: 25px;
}

.PDFPage > canvas {
  max-width: 100%;
  height: auto !important;
}

.react-pdf__Page__textContent {
  display: none;
}

#pageData {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  width: 99%;
}

#canv-invisible {
  display: none;
}

.SideMenu-enab {
  color: #14a3c4;
  text-align: left;
}

.SideMenu-disab {
  color: #bcbcbc;
  text-align: left;
}

.SideMenu-enab a {
  color: #14a3c4;
  text-align: left;
}

.SideMenu-disab a {
  color: #bcbcbc;
  text-align: left;
}

.SideMenu-icon {
  text-align: right;
}

.SideMenu-icon-cont {
  text-align: right;
}

.Summary-heading {
  color: #bcbcbc;
  font-weight: bold;
}

/* Formula from https://www.bootply.com/qdhRy1G31W. For Bootstrap 3 - but
adapted for Bootstrap 4. */

.inset {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.6),
    0 1px 1px rgba(0, 0, 0, 0.1);
  background-color: transparent !important;
  z-index: 999;
}

.inset img {
  border-radius: inherit;
  width: inherit;
  height: inherit;
  display: block;
  position: relative;
  z-index: 998;
}

.plan-table-th {
  width: 20%;
}

.gen_invisible {
  display: none;
}

/*
.plan-table {
  display: block;
  overflow: auto;
  max-height: 40vh; 
  width: 100% !important;
}

.plan-table-thead {
  display: block;
  width: 100% !important;
}

.plan-table-tbody {
  display: block;
  width: 100% !important;
}
*/

.plan-table-tr {
}

dt,
dd {
  text-align: left;
}

.qtacpa {
  font-size: 40px;
  font-weight: bold;
}

.circlearound {
  /* border: 5px solid #163c9c; */
  margin-bottom: 20px;
  height: 35px;
  width: 210px;
  margin-left: -10px;
  margin-right: 0px;
  border-radius: 6px;
}

.circlearound .md-48 a {
  font-size: 48px;
}

.imgorgen {
  width: 250px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.checkcorrect {
  margin-top: 10px;
  margin-left: -32px;
}

.searchbutton {
  color: white;
  background-color: #14a3c4;
}

.input-group-text {
  background-color: #14a3c4;
  border: 1px solid #14a3c4;
}
.form-control {
  border: 1px solid #14a3c4;
}
.modal-header {
  background-color: #14a3c4;
}
.modal-title {
  color: white;
}
.modal-header button span {
  color: white;
}
.project-name-col {
  text-align: left;
}
.fineprint {
  font-size: 10px;
}
.selectedcard {
  background-color: rgba(20, 163, 196, 0.2);
}
.inaccessiblecard {
  background-color: lightgrey;
}
.card-link {
  color: black;
}
.card-link:hover {
  color: #444444;
}

.form-check-new {
  padding-left: 3.25rem;
}

.overflowit {
  overflow:auto;
}